.map-satelite-toggle {
    position: absolute;
    width: 64px;
    height: 64px;
    z-index: 1000; // just above "Google" text on google maps layer
    background-image: url('../../images/map-icons/satellite-active.png');
    background-size: cover;
    cursor: pointer;
    bottom: 0;
    left: 0;
    outline: none;
    border-radius: 5px;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    margin: 10px;

    &.-is-active {
        background-image: url('../../images/map-icons/satellite-inactive.png');
    }
}
