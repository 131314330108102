@import "~leaflet/dist/leaflet.css";
@import "~leaflet.locatecontrol/dist/L.Control.Locate.min.css";

.poi-type-starting-point {
    z-index: 99999 !important; // Make sure start poi's are on top
}

.leaflet-container {
    background-color: #D5E6CB;

    &.show-routes-layer .route-layer {
        display: block;
    }

    &.show-pois-layer .poi-layer {
        display: block;
    }

    &.show-start-pois-layer .start-poi-layer {
        display: block;
    }

    .leaflet-marker-icon.-animate {
        animation-name: pulse;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
    }
}

.route-layer, .poi-layer, .start-poi-layer {
    display: none;
}

.map {
    display: none;
    @include screen-size(medium, large) {
        display: block;
   }

    .iframe-mode &{
        display: block;
    }
}

.map--header-box {
    display: block;
    text-decoration: none;
    position: relative;

    @include screen-size(mini, small) {
        width: 100%;
        max-width: 100%;
        height: 40vh;
    }

    @include screen-size(medium, large) {
        display: none;
    }
}

.savetiles i, .rmtiles i {
    background-image: url('../../images/download.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.savetiles span {
    position: absolute;
    left: -30px;
    font-weight: bold;
}

.rmtiles i {
    background-image: url('../../images/trash.svg');
}

.leaflet-bar a:focus {
    text-decoration: none;
}

.leaflet-control-locate {
    &.requesting {
        a {
            color: red;
        }
    }

    .fa-map-marker {
        background-image: url('../../images/map-icons/current-location.svg');
        background-size: 65%;
        width: 100%;
        height: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;

        @supports (mask-image: url('../../images/map-icons/current-location.svg')) {
            mask-image: url('../../images/map-icons/current-location.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            background-image: none;
            background-color: currentColor;
            mask-size: 65%;
        }
    }
}

.leaflet-control-container {
    .browser-print-holder {
        background-color: white;
        color: black;

        &:hover {
            background-color: get-color("primary");
            color: white;
        }
    }

    .browser-print-mode {
        color: inherit;
        margin: 0;

        &::before, &::after {
            display: none;
        }
    }
}

@keyframes pulse {
    from {
        opacity: .7;
        top: 0;
    }

    50% {
        opacity: 1;
        top: -5px;
    }

    to {
        opacity: .7;
        top: 0;
    }
}
