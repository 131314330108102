/* HEADER-BOX */
.header-box {
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin-bottom: 3rem;

    @include screen-size(medium, large) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: stretch;
        align-content: stretch;
        border: 5px solid get-color("white");
    }

    @include screen-size(large) {
        width: calc(100% + 30px);
        margin-left: -30px;
    }

    @media print {
        margin-top: 30px;
        margin-bottom: 0;
    }
}

.header-box--pulled-up {
    @include screen-size(medium, large) {
        margin-top: -165px;
    }
}

.header-box__content {
    @include default-typography((font-size, line-height));
    box-sizing: border-box;
    background: get-color("secondary");

    display: block;
    padding: 20px;

    @include screen-size(medium, large) {
        border-right: 5px solid get-color("white");
        flex: 1 0;
        align-self: auto;

    }

    @include screen-size(large) {
        padding: 20px 30px 30px 30px;
    }

    @media print {
        padding: 0;
    }
}

.header-box__inner {
    width: 100%;
}


.header-box__extra {
    display: block;
    position: relative;
    min-width: 225px;
    overflow: hidden;
    flex: 1 0;

    a {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }

    @include screen-size(mini, small) {
        width: 225px;
        img {
            width: 100%;
            max-width: 100%;
        }
    }

    @include screen-size(medium, large) {
        flex: 0 1 auto;
        align-self: stretch;
    }
}

.header-box__extra--map {
    @include screen-size(mini, small) {
        display: none;
    }
    @include screen-size(medium, large) {
        img {
            max-width: 225px;
        }
    }
}


.header-box__title {
    @extend .title-1;
    color: get-color("white");
    margin-bottom: 10px;
    line-height: 1;
}

.header-box__subtitle {
    @extend .title-3;
    color: get-color("black-3");
}

.header-box__link {
    flex: 0 1 100%;
    margin-top: 15px;
    text-align: right;
    float: right;
}


.header-box--alt {
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    @include screen-size(large) {
        width: calc(100% + 30px);
        margin-top: -44px;
    }

    .header-box__content {
        border: 0;
        width: 100%;
    }

    .header-box__title {
        margin-bottom: 0;
        line-height: 1;
    }
}


.header-box--cart {
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0;

    @include screen-size(large) {
        margin-top: -44px;
        margin-bottom: 0;
    }

    &:hover, &:focus {
        text-decoration: none;

        .header-box__content {
            background: darken(get-color("primary"), 5%);
        }
    }

    &:active {
        text-decoration: none;

        .header-box__content {
            background: darken(get-color("primary"), 10%);
        }
    }

    .header-box__content {
        border: 0;
        width: 100%;
        background: get-color("primary");
        padding-left: 10px;
        padding-right: 10px;
    }

    .header-box__number {
        display: inline-block;
        vertical-align: middle;

        background: get-color("white");
        text-align: center;
        padding: 8px;
        margin-right: 10px;
        min-width: 18px;
        border-radius: 999px;

        font-size: 18px;
        line-height: 1;
        font-weight: 700;
    }

    .svg-icon {
        display: inline-block;
        font-size: 30px;
        color: get-color("white");
        vertical-align: middle;
        margin-right: 10px;
    }

    .header-box__title {
        display: inline-block;
        vertical-align: middle;
        font-size: 23px;
        margin-bottom: 0;
        line-height: 1;
    }
}
