/* Button */
.button--reset {
    transition: background .2s ease;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;

    color: get-color("white");
    background: get-color("primary");
    outline: none;

    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;

    vertical-align: middle;
}

.button {
    @extend .button--reset;
    border-radius: 2px;

    box-sizing: border-box;
    padding: 15px 18px;
    margin-bottom: .5em;

    background: get-color("primary");
    color: get-color("white");

    &:hover, &:focus {
        background: darken(get-color("primary"), 5%);
        color: get-color("white");
    }

    &:active {
        background: darken(get-color("primary"), 10%);
        color: get-color("white");
    }
}

.button--secondary {
    background: get-color("primary");
    color: get-color("white");
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;

    &:hover, &:focus {
        background: darken(get-color("primary"), 5%);
        color: get-color("white");
    }

    &:active {
        background: darken(get-color("primary"), 10%);
        color: get-color("white");
    }
}

.button--large {

}

.button--full-width {
    width: 100%;
    text-align: center;
}

.button--no-style,
.button--no-style:hover,
.button--no-style:focus {
    border-radius: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    color: get-color("primary");
    font-weight: 400;
}


@keyframes scaleout {
    0% {
        transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

.button--loading[disabled] {
    position: relative;
    //overflow: hidden;
    color: transparent;
    &:hover, &:focus {
        color: transparent;
    }
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin: -20px -20px;
        background-color: get-color("white");
        border-radius: 100%;
        animation: scaleout 1.0s infinite ease-in-out;
    }
}

.button--filter {
    transition: all .3s ease;

    position: relative;

    background: get-color("pink");
    font-size: 17px;
    font-weight: 700;

    margin-top: 37px;

    border-radius: 0 5px 5px 0;
    white-space: nowrap;

    cursor: pointer;
    z-index: 10;

    @include screen-size(large) {
        position: absolute;
        left: 272px;
        top: 10px;
    }

    @include screen-size(mini, small, medium) {
        display: none;
    }

    .-is-offline &{
        display: none;
    }

    svg.svg-icon {
        position: relative;
        top: 2px;
        padding-right: 1px;
    }
}

.button--filter-active {
    left: 0;
}

#openButton, #installButton, #installCancelButton {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 9999;
}
