.filter-overlay {
    background-color: rgba(255, 255, 255, .4);
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;

    @include screen-size(large, medium) {
        display: none;
    }
}
