/* STYLE */
.pagination {
    box-sizing: border-box;
    @extend .clearfix;

    width: 100%;
    margin-bottom: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 7px 10px;
    position: relative;

    color: $default-font-color;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    background-color: transparent;

    border: 0;

    select {
        margin: 0;
        padding: 0;
    }

    .disabled {
        display: none;
    }

    @media print {
        display: none !important;
    }

}

.pagination__holder {
    @extend .clearfix;

    min-height: 40px;
    line-height: 40px;

    margin: 0 auto;
    display: block;
    list-style: none;
    position: relative;

    @include screen-size( large ) {
        width: 100%;
        margin: 0;
        padding: 2px;
        float: left;
    }
}

.pagination__previous, .pagination__next {
    position: absolute;
    top: 0;

    margin: 0;
    padding: 0;

    // BUTTON STYLE
    box-sizing: border-box;
    border-radius: 0;

    // position: relative;
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    border: none;
    vertical-align: middle;
    line-height: 40px;
    text-decoration: none;

    color: get-color("primary");
    background-color: get-color("white");
    // END
    font-family: $font-secondary;

    @include screen-size( mini, small ) {
        padding: px-2-rem( 15px ) px-2-rem( 20px );
    }

    &:hover, &:focus {
        color: get-color("primary");
        background-color: get-color("white");
        text-decoration: none;
        &:before, &.before,
        &:after, &.after {
            color: #072f49;
        }
    }

    &:active {
        color: get-color("primary");
        background-color: darken(get-color("white"), 10%);
        text-decoration: none;
        &:before, &.before,
        &:after, &.after {
            color: #072f49;
        }
    }

    &:before, &.before,
    &:after, &.after {
        display: inline-block;
        text-decoration: none;
        color: get-color("primary");
        line-height: 1;

        @include screen-size(large) {
            font-size: px-2-rem(18px);
        }
        @include screen-size(medium) {
            font-size: px-2-rem(18px);
        }
        @include screen-size(small, mini) {
            font-size: px-2-rem(20px);
        }
    }

    @include screen-size( mini, small ) {
        // Remove the text, only leave the generated content
        display: inline-block;
        position: relative;
        font-size: 0;
        width: 0;
        height: 0;
        &:before, &.before,
        &:after, &.after {
            position: absolute;
            top: px-2-rem(5px);
            left: 0;
            right: 0;
            width: 100%;
        }
        // End
    }

    @include screen-size( medium, large ) {
        &:before, .before { padding-right: px-2-rem( 6px ); }
        &:after, .after { padding-left: px-2-rem( 6px ); }
    }
}

.pagination__previous {
    left: 0;

    &:before, &.before {
        @extend .icon !optional;
        @extend .icon-content--larr !optional;
        font-size: 12px;
    }

    @include screen-size( mini, small ) {
        float: left;
    }

}

.pagination__next {
    right: 0;

    &:after, &.after {
        @extend .icon !optional;
        @extend .icon-content--rarr !optional;
        font-size: 12px;
    }

    @include screen-size( mini, small ) {
        float: right;
    }

}

.pagination__all {
    display: inline-block;
    width: auto;
    text-align: center;


    margin: 0 auto;
    padding: 0;
    color: get-color("primary");

    font-size: px-2-rem(18px);
    line-height: px-2-rem(40px);
    font-weight: 400;
    font-family: $font-primary;

    @include screen-size(mini, small) {
        line-height: 1;
    }

    strong {
        color: get-color("primary");
        font-weight: 700;
    }

    @include screen-size(large) {
        margin-right: px-2-rem( 10px );
        float: right;
    }
}