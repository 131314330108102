/* MENU-BUTTON */
.menu-button {
    float: right;
    color: get-color("white");
    font-size: 16px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 11px;

    text-decoration: none;
    &:hover, &:focus {
        text-decoration: none;
    }

    .icon {
        font-size: 12px;
    }
    @include screen-size(large) {
        display: none;
    }
}
