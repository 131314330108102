/* Filter-result Header */
// TODO
.filter-result__header {
    @extend .clearfix;
    border-bottom: 1px solid #d6d6d7;
    margin-bottom: 20px;
    padding-bottom: 10px;

    // Todo
    [class*="title-"] {
        margin-bottom: 0;
        font-family: $font-secondary;
        font-weight: 700;
    }
}
