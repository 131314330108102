/* Helper classes */
.h--no-margin {
    margin: 0;
}

.h--no-margin-bottom {
    margin-bottom: 0;
}

.h--margin-bottom-1 {
    margin-bottom: 1em;
}

.h--margin-bottom {
    margin-bottom: 2em;
}

.h--mini-margin-bottom {
    margin-bottom: 5px;
}

.h--small-margin-bottom {
    margin-bottom: 10px;
}

.h--medium-margin-bottom {
    margin-bottom: 20px;
}

.h--large-margin-bottom {
    margin-bottom: 30px;
}

.h--large-margin-top {
    margin-top: 30px;
}

.h--extra-large-margin-bottom {
    margin-bottom: 40px;
}

.h--no-padding {
    padding: 0;
}

.h--small-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.h--medium-padding {
    padding-top: 30px;
    padding-bottom: 30px;
}

.h--medium-padding-bottom {
    padding-bottom: 30px;
}

.h--large-padding {
    padding-top: 50px;
    padding-bottom: 50px;
}

.h--large-padding-bottom {
    padding-bottom: 40px;
}

.h--bottom-border {
    border-bottom: 1px solid get-color("orange");
}

.h--pulled-up {
    margin-top: -20px;
}

/* HELPER-EQUAL-HEIGHT */
.h--equal-height,
.h--flexbox {
    display: flex;
    flex-wrap: wrap;
}

.h--v-large__equal-height,
.h--v-large__flexbox {
    @include screen-size(large) {
        display: flex;
        flex-wrap: wrap;
    }
}

.h--v-medium__equal-height,
.h--v-medium__flexbox {
    @include screen-size(medium) {
        display: flex;
        flex-wrap: wrap;
    }
}

.h--v-small__equal-height,
.h--v-small__flexbox {
    @include screen-size(small) {
        display: flex;
        flex-wrap: wrap;
    }
}

.h--v-mini__equal-height,
.h--v-mini__flexbox {
    @include screen-size(mini) {
        display: flex;
        flex-wrap: wrap;
    }
}

.h--flex {
    flex: 1;
}

.h--hide-mini {
    @include screen-size(mini) {
        display: none;
    }
}

.h--hide-small {
    @include screen-size(small) {
        display: none;
    }
}

.h--hide-medium {
    @include screen-size(medium) {
        display: none;
    }
}

.h--hide-large {
    @include screen-size(large) {
        display: none;
    }
}
