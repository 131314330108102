.close {
    position: relative;
    top: 3px;

    font-size: 13px;
    float: right;
    opacity: .6;

    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
        opacity: 1;
    }

    @include screen-size(mini, small, medium) {
        display: none;
    }
}
