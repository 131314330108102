/* TAG */
.tag {
    display: inline-block;
    box-sizing: border-box;

    padding: 6px 5px 7px 5px;
    border-radius: 2px;
    margin-bottom: .28em;

    font-size: 16px;
    line-height: 1;
    font-weight: 700;
}


.tag--yellow {
    background: get-color("yellow");
    color: get-color("black")
}

.tag--red {
    background: get-color("red");
    color: get-color("white");
}

.tag--green {
    background: get-color("secondary");
    color: get-color("white");
}

.tag--blue {
    background: get-color("blue");
    color: get-color("white");
}

.tag--bluelight {
    background: get-color("blue-3");
    color: get-color("white");
}

.tag--orange {
    background: get-color("orange");
    color: get-color("white");
}

.tag--pink {
    background: get-color("pink-2");
    color: get-color("white");
}

.tag--brown {
    background: get-color("red-2");
    color: get-color("white");
}

.tag--purple {
    background: get-color("purple");
    color: get-color("white");
}

.tag--gray {
    background: get-color("gray-4");
    color: get-color("white");
}

.tag-label {
    margin-left: .5em;
}
