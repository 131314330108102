/* Filter */
.filter {
    @include default-typography((font-size, line-height));
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    background: get-color("white");
    margin-bottom: 1rem;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

}

.filter__head {
    @include clearfix;
    display: block;
    background: get-color("white");
    padding: 35px 10px 4px 22px;
    margin-bottom: 3px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, .1);
}

.filter__title {
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin-bottom: .5rem;
    color: get-color("black");
}

.filter__body {
    background: get-color("white");
    padding: 16px 22px;
}

.filter__content {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1rem;
}