/* SHOP-BOX */
.shop-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.shop-box__part {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    font-size: 18px;
    line-height: 1.25;
    vertical-align: middle;

    > .button {
        margin-top: 10px;
        margin-bottom: 10px;
        white-space: nowrap;
    }

    & > * {
        display: inline-block;
        vertical-align: middle;

        margin-right: 10px;
        margin-bottom: 0;

        flex: 1;
    }

    & > .line-subtotal {
        margin-right: 0;
    }

    input {
        @include screen-size(large) {
            max-width: 50px;
        }
    }
}

.shop-box__part--alt {
    display: inline-block;
    @include screen-size(medium, large) {
        text-align: right;
    }
    &:last-child {
        min-width: inherit;
    }
    input {
        margin-left: 5px;
        max-width: 50px;
    }
}

.product-discount {
    color: get-color("secondary");
    font-size: 90%;
}



// .cart__price {
//     margin-bottom: 30px;
// }

// .cart__price__label,
// .cart__price__to-pay {
//     text-align: right;
//     margin-bottom: 0 !important;
//     font-size: 26px;
//     line-height: 36px;
//     font-weight: 400;
//     font-family: $font-secondary;
//     text-transform: uppercase;

//     @include screen-size(mini, small) {
//         font-size: 20px;
//     }

//     select {
//         vertical-align: middle;
//     }
// }

// .cart__price__label {
//     @include screen-size(mini, small) {
//         width: 65%;
//         float: left;
//     }
// }
// .cart__price__to-pay {
//     @include screen-size(mini, small) {
//         width: 35%;
//         float: right;
//     }
// }

// .cart__total {
//     margin-bottom: 40px;
// }

// .cart__price__total {
//     font-size: 34px;
// }

// .cart__continue-shopping {
//     display: block;
//     @include screen-size(mini, small) {
//         width: 100%;
//         text-align: center;
//     }
//     @include screen-size(medium, large) {
//         float: left;
//         width: 48%;
//         line-height: 45px;
//     }
// }

// .cart__next-step {
//     display: block;
//     @include screen-size(mini, small) {
//         width: 100%;
//         text-align: center;
//         margin-bottom: 10px;
//     }
//     @include screen-size(medium, large) {
//         float: right;
//         width: 48%;
//         text-align: right;
//     }
// }
