/* Defaults */
a {
    color: $default-link-color;
    text-decoration: $default-link-text-decoration;

    &:hover, &:focus {
        text-decoration: $default-link-text-decoration-hover;
    }
}

p a {
    color: get-color("secondary");
    text-decoration: none;
    font-weight: 700;
    &:hover, &:focus {
        text-decoration: underline;
    }
}

a[href^=mailto] {
}

a[rel=external] {
}

a[href^=tel] {
    text-decoration: inherit;
    color: inherit;
    cursor: inherit;
    &:hover, &:focus {
        text-decoration: inherit;
        color: inherit;
        cursor: inherit;
    }
}

/* Link */
.link {
    @extend a;

    color: get-color("secondary");
    font-weight: 700;
    display: inline-block;
    padding-right: 10px;
    padding-left: 0;
    border: 0;
    box-shadow: none;
}

.link--more {
    position: relative;
    // color: $link--more-color;
    &:after, .after {
        @extend .icon !optional;
        @extend .icon-content--arrow-right !optional;
        display: inline-block;
        // content: $more-link-content;
        color: get-color(secondary);
        position: relative;
        top: 0px;
        font-size: 12px;
        margin-left: 7px;
    }
}

.link--arrowed {
    position: relative;
    padding-left: 20px;
    &:before {
        @extend .icon !optional;
        @extend .icon-content--arrow-right !optional;
        color: get-color("primary");
        font-size: 9px;
        position: absolute;
        top: 8px;
        left: 0;
    }
}

.link--alt {
    font-size: 14px;
    font-weight: 700;
    color: get-color("primary");
    text-decoration: none;
    margin-right: 5px;

    &:hover, &:focus {
        text-decoration: underline;
    }
}
.link--small {
    font-size: 14px;
    font-weight: 700;
    color: get-color("blue");
    text-decoration: underline;
    margin-right: 5px;

    &:hover, &:focus {
        text-decoration: none;
    }
}

.link--iconed {
    text-decoration: underline;
    color: get-color("white");
    margin-right: 20px;
    font-size: 16px;

    .svg-icon {
        margin-right: 10px;
        position: relative;
        top: 2px;
    }

    &:hover, &:focus {
        text-decoration: none;
        .icon,
        .svg-icon {
            color: inherit;
        }
    }
}

.link--fav {
    .svg-icon {
        transition: color .2s linear;
    }
    &:hover, &:focus {
        .svg-icon--heart {
            color: get-color("red");
        }
    }
}


.link--mailto {
    font-weight: 400;
    color: get-color("primary");
    text-decoration: underline;
    &:hover, &:focus {
        text-decoration: none;
    }
}

.link--tel {

}

.link--external {

}

.link--remove {
    text-align: right;
    float: right;

    &:hover, &:focus {
        text-decoration: none;
        color: get-color("red");
    }

    @include screen-size(mini, small) {
        position: relative;
        margin-top: -35px;
    }
}

.link--regular {
    color: get-color("black");
}
