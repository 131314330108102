/* MAP-side */
.map-side {
    transition: all .3s ease;
    transform: translate3d(200px,0,0);

    box-sizing: border-box;

    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;

    padding: 20px;

    background: get-color("white");
    font-size: 14px;
    line-height: 1.25;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    z-index: 1;

    @media print {
        display: none;
    }
}

.map-side--active {
    transform: translate3d(0,0,0);
}
