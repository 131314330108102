html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

header {
    flex: none;
}

main {
    box-sizing: border-box;
    width: 100%;
    flex: 1 0 auto;
}

footer {
    flex: none;
}
