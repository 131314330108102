/* VARIABLES */
$jumbotron-background-color:                         get-color(white);
$jumbotron-border-color:                             #e9e9e9;

// Jumbotron title
$jumbotron__title-color:                             #1a1a1a;
$jumbotron__title-font-weight:                       normal;
$jumbotron__title-text-transform:                    none; // none, Capitalize, UPPERCASE, lowercase

$jumbotron__title-large-font-size:                   px-2-rem(32px);
$jumbotron__title-large-line-height:                 px-2-rem(35px);
$jumbotron__title-medium-font-size:                  px-2-rem(32px);
$jumbotron__title-medium-line-height:                px-2-rem(35px);
$jumbotron__title-small-font-size:                   px-2-rem(28px);
$jumbotron__title-small-line-height:                 px-2-rem(32px);

/* JUMBOTRON */
.jumbotron {
    box-sizing: border-box;
    @extend .clearfix;
    margin: 0;
    margin-bottom: $default-block-margin;
    padding: px-2-rem( 20px );

    text-align: center;

    background-color: $jumbotron-background-color;
    border: 1px solid $jumbotron-border-color;

    p {
        margin-bottom: 0;
    }

}

.jumbotron__title {
    @include text-color($default-font-color);
    @include screen-size(large) {
        font-size: $jumbotron__title-large-font-size;
        line-height: $jumbotron__title-large-line-height;
    }
    @include screen-size(medium) {
        font-size: $jumbotron__title-medium-font-size;
        line-height: $jumbotron__title-medium-line-height;
    }
    @include screen-size(small, mini) {
        font-size: $jumbotron__title-small-font-size;
        line-height: $jumbotron__title-small-line-height;
    }
    @include default-typography(margin);

    display: block;
    text-transform: none;

    a {
        text-decoration: none;
    }

    color: $jumbotron__title-color;
    font-weight: $jumbotron__title-font-weight;
    text-transform: $jumbotron__title-text-transform;
}

.jumbotron__content {
    margin-left: auto;
    margin-right: auto;
}