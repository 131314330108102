/* FILTER__LEGEND */
.filter__legend {
    display: inline-block;
    cursor: pointer;

    color: get-color("black");

    font-size: 16px;
    line-height: 1;
    margin: 0 0 1rem 0;
}

.filter__legend__arrow {
    padding-left: 5px;
    &.dropdown__toggle-open:before {
        content: '';
        display: inline-block;
        position: relative;
        top: 2px;

        border: {
            top:    5px solid get-color("black");
            right:  5px solid transparent;
            bottom: 5px solid transparent;
            left:   5px solid transparent;
        }
    }
    &.dropdown__toggle-closed:before {
        content: '';
        display: inline-block;
        position: relative;
        top: -2px;

        border: {
            top:    5px solid transparent;
            right:  5px solid transparent;
            bottom: 5px solid get-color("black");
            left:   5px solid transparent;
        }
    }
}
