/* LISTS */
ul, ol {
    max-width: $paragraph-max-width;
    list-style: none;
    margin-bottom: 2em;
    padding-left: 0;
    display: table;

    li {
        display: table-row;
        line-height: 1.5;
        margin-top: .75em;
        margin-bottom: .75em;

        &:before {
            display: table-cell;
            padding-right: 0.4em;
            vertical-align: top;
        }

        &:after {
            content: "";
            display: block;
            margin-bottom: 0.5em;
        }
    }
}

ul {
    li {
        &:before {
            content: '\2022';
        }
    }
}

ol {
    li {
        counter-increment: table-ol;
        &:before {
            content: counter(table-ol) ".";
            text-align: right;
        }
    }
}

[class*="title-"] + ol,
[class*="title-"] + ul,
h1 + ol, h1 + ul,
h2 + ol, h2 + ul,
h3 + ol, h3 + ul,
h4 + ol, h4 + ul,
h5 + ol, h5 + ul,
h6 + ol, h6 + ul,
p + ol, p + ul {
    margin-top: 0;
}


.list--arrowed {
    li {
        position: relative;

        &:before {
            @extend .icon !optional;
            @extend .icon-content--arrow-right !optional;
            color: get-color("secondary");
            font-size: 9px;
            margin-left: 7px;
            padding-right: 15px;
            position: relative;
            top: 10px;
        }
    }
}
