p {
    margin: 0;
    margin-bottom: 2em;

    color: $default-font-color;
    max-width: $paragraph-max-width;
}

.paragraph--auto-width {
    max-width: none;
}
