/* FILTER__FIELD--CHECKBOX */
.filter__field--checkbox {
    @extend .filter__field;

    /* Custom Styling in label */
    display: none;

    & + label {
        position: relative;
        padding-left: 1.5em;

        &:before {
            content: '';

            display: inline-block;
            width: 12px;
            height: 12px;

            position: absolute;
            left: 0;
            top: 3px;

            vertical-align: middle;

            border-radius: 3px;
            box-shadow: 0 0 2px rgba(#000, .4) inset, 0 2px 0 rgba(#000, .05) inset;
            background-color: #ffffff;

        }
        &:after {
            display: inline-block;
            width: 12px;
            height: 12px;

            position: absolute;
            left: 0;
            top: 4px;

            color: get-color("white");
            text-align: center;
            font-size: 9px;
        }
    }

    &:checked + label {
        &:before {
            background: #1a94ff;
        }
        &:after {
            content: '\2714'; // heavy checkmark
        }
    }
}
