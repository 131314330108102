/* form__FIELD--CHECKBOX */
.form__field--checkbox {
    position: relative;
    top: 2px;

    & + label {
        margin-left: 10px;
    }

    &.form__field {
        width: auto;
    }
}
