.heart-icon {
    transition: color .2s ease;

    position: relative;
    top: -1px;

    display: inline-block;
    vertical-align: middle;

    width: 20px;
    height: 18px;

    margin-right: 7px;

    color: inherit;

    &:before,
    &:after {
        color: inherit;

        position: absolute;
        content: "";
        left: 10px;
        top: 0;
        width: 10px;
        height: 16px;
        background: currentColor;
        border-radius: 10px 10px 0 0;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
    }

    &:after {
        left: 0;
        transform: rotate(45deg);
        transform-origin: 100% 100%;
    }
}
