pwa-install {
    --install-button-color: #87b83f;

    &::part(openButton) {
        display: none;
    }

    &::part(installModal) {
        line-height: 1.3;
    }
}
