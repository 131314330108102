/* NAV-language */
.nav-language {
    display: inline-block;
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: auto;
    list-style: none;
    text-align: center;
}

.nav-language__item {
    display: inline-block;
    margin: 0;
    padding: 0 (25px/2);
    text-align: center;

    &:before {
        display: none;
    }

    & > a {
        font-size: 17px;
        font-weight: 700;
        color: get-color("white");
        line-height: 1;
    }

    @include screen-size(large) {
        padding-top: 18px;
        padding-bottom: 14px;
    }
}

.nav-language__item--active {
    display: none;
}