/* Form Entry */
%form__entry {
    @include clearfix;
    box-sizing: border-box;
    display: block;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

.form__entry {
    @extend %form__entry;
}

.form__entry--checkbox {
    @extend %form__entry;
    display: flex;
    align-items: flex-start;
}
