/* NAV-MAIN */
.nav-main {
    max-width: 100%;
    @include screen-size(mini, small, medium) {
        display: block;
        margin: 0 0 10px 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid rgba(get-color("white"), .25);
    }
    @include screen-size(large) {
        display: inline-block;
        float: left;
        margin: 0;
        padding: 0 0 0 137px;
    }
}

.nav-main__item {
    margin: 0;
    padding: 0;
    color: get-color("white");

    &:before {
        display: none;
    }

    @include screen-size(mini, small, medium) {
        padding: 10px 20px 5px 0;
        border-top: 1px solid rgba(get-color("white"), .25);
        display: block;
        text-align: right;
        line-height: 1;

        background-color: rgba(get-color("black"), 0.08);
    }

    @include screen-size(large) {
        display: inline-block;
        padding-top: 18px;
        padding-bottom: 14px;
        padding-right: 35px;
    }

    & > a {
        font-size: 17px;
        font-weight: 700;
        color: get-color("white");
        line-height: 1;
    }
}
