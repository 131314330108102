$breakpoint-regions: 800px;
$breakpoint-one-column-list: 650px;

.regions-map {
    @extend .grid__container;

    display: flex;
    justify-content: space-around;
    margin-bottom: 2em;
    flex-direction: row-reverse;

    @media (max-width: $breakpoint-regions) {
        flex-direction: column;
        margin-bottom: 0;
    }

    > svg {
        flex: 1 1 40%;
        max-width: 100%;

        @media (max-width: $breakpoint-regions) {
            flex: 1 1 auto;
        }

        a:hover {
            > path {
                fill-opacity: .4;
            }
        }
    }

    &__content {
        flex: 0 1 40%;
        padding-top: 40px;

        @media (max-width: $breakpoint-regions) {
            padding-top: 0;
            padding-bottom: 20px;
        }
    }

    &__list {
        display: block;
        list-style: none;
        counter-reset: colored-counter;
        margin-bottom: 0;
        margin-top: 1em;

        @media (max-width: $breakpoint-regions) and (min-width: $breakpoint-one-column-list) {
            column-count: 2;
        }

        @media (max-width: $breakpoint-regions) {
            margin-left: auto;
            margin-right: auto;
        }

        > li {
            counter-increment: colored-counter;
            margin-bottom: .5em;
            margin-top: 0;
            display: block;
            position: relative;
            padding-left: 50px;

            &::before {
                background-color: black;
                border-radius: 50%;
                box-sizing: border-box;
                color: white;
                content: counter(colored-counter);
                display: inline-block;
                font-size: 15px;
                font-weight: bold;
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                text-align: center;
                width: 30px;
                position: absolute;
                top: 0;
                left: 0;
            }

            &::after {
                display: none;
            }

            &.-color-yellowdark::before { background-color: #C8A700; }
            &.-color-bluedark::before { background-color: #264578; }
            &.-color-khaki::before { background-color: #A2A356; }
            &.-color-red::before { background-color: #C22E27; }
            &.-color-fluo::before { background-color: #D3D300; }
            &.-color-green::before { background-color: #00632E; }
            &.-color-yellow::before { background-color: #FAC300; }
            &.-color-blue::before { background-color: #5980A9; }
            &.-color-brown::before { background-color: #CB7E0D; }
            &.-color-brown-dark::before { background-color: #8A4518; }
            &.-color-purple::before { background-color: #7F6FAB; }

            > a {
                color: get-color('black');
                font-size: 18px;
            }
        }
    }
}

.regions-map--smaller {
    @extend .regions-map;
    padding: 0;

    > svg {
        flex-basis: 70%;

        @media (max-width: $breakpoint-regions) {
            flex-basis: auto;
        }
    }

    .regions-map__content {
        align-items: center;
        display: flex;
        flex-basis: 30%;
    }

    .regions-map__list {
        zoom: .8;
    }
}
