/* form__FIELD--RADIO */
.form__field--radio {
    /* NATIVE STYLING */
    /*
    display: inline-block;
    width: auto;

    margin-left: 3px;
    margin-right: 0.5em;
    vertical-align: bottom;
    */

    /* Custom Styling in label */
    display: none;


    & + label {
        position: relative;
        padding-left: 1.5em;

        &:before {
            content: '';

            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 16px;

            position: absolute;
            left: 0;
            top: 1px;

            vertical-align: middle;

            box-shadow: 0 0 1px rgba(35,37,41,0.15) inset, 0 1px 0 rgba(35,37,41,0.075) inset;
            background-color: #dddee2;

            color: get-color("white");
            text-align: center;
        }
    }

    &:checked + label {
        &:before {
            background: get-color("secondary");
        }
        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 8px;

            position: absolute;
            top: 5px;
            left: 4px;

            background: get-color("white");
        }
    }
}
