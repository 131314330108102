// Flash-message
.flash-container {
    opacity: 1;
    transition: opacity 0.8s;

}

.flash-container--hide {
    opacity: 0;
}

.flash-message {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;

    & > *:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 0;
        max-width: 100%;
        color: inherit;
        line-height: 1;
        padding-left: 35px;
    }

    &:before {
        display: inline-block;
        position: absolute;
        left: 20px;
        @extend .icon;
        width: 25px;
        text-align: center;
    }
}


.flash-message--success {
    background: rgba(green, .1%);
    color: green;
    &:before {
        @extend .icon--check;
    }
}

.flash-message--error {
    background: rgba(red, .1%);// fallback
    color: red;
    &:before {
        @extend .icon--cross;
    }
}

.flash-message--info {
    background: rgba(blue, .1%);
    color: black;
    &:before {
        @extend .icon--info;
    }
}

.flash-message--warning {
    background: rgba(orange, .1%);
    color: black;
    &:before {
        @extend .icon--warning;
    }
}