/* BLOCK */
.block {
    @include clearfix;
    width: 100%;
    box-sizing: border-box;
    background: get-color("white");
    margin-bottom: 10px;
    padding: 15px;

    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, .1);
}


.block--popup {
    box-sizing: border-box;

    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    overflow-x: hidden;
    overflow-y: scroll;

    background: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .block__image {
        background-repeat: no-repeat;
        background-color: lightgray;

        &.-fullscreen {
            border-radius: 6px;
            margin: -20px;

            @include screen-size(small) {
                height: 250px;
                max-height: 25vh;
            }
        }

        &:not(.-fullscreen) {
            @include screen-size(medium, large) {
                border-radius: 6px 0 0 6px;
                min-height: 400px;
                margin-right: 20px;
                margin-bottom: -20px;
            }

            @include screen-size(large) {
                min-width: 400px;
            }

            @include screen-size(medium) {
                min-width: 300px;
            }

            @include screen-size(small, medium, large) {
                display: block;
                margin-left: -20px;
                margin-top: -20px;

                background-position: center center;
                background-size: cover;

                flex: 1 0 auto;

                &.block__image--icon {
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-size: initial;
                    min-height: 130px;
                    margin: 5px 0;
                }
            }

            @include screen-size(mini, small) {
                margin-bottom: 20px;
                border-radius: 6px 6px 0 0;
                z-index: 1;
            }

            @include screen-size(small) {
                margin-right: -20px;
                width: calc(100% + 40px);
                height: 250px;
                max-height: 25vh;
            }

            @include screen-size(mini) {
                display: none;
            }
        }
    }

    .block__inner {
        box-sizing: border-box;
        display: flex;
        width: 95%;
        max-height: 90%;

        @media (min-height: 666px) and (min-width: 681px) {
            max-height: 550px;
            min-height: 300px;
        }

        @include screen-size(large) {
            width: auto;
            min-width: 900px;
            max-width: 1024px;
        }
        @include screen-size(mini, small) {
            flex-direction: column;
        }
        margin: 10px auto;
        padding: 20px;

        background: get-color("white");
        border-radius: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    .block__content {
        display: block;
    }

    .block__tags {
        float: none;
        margin-left: 0;
        margin-top: 1rem;
        text-align: left;
        max-width: none;
    }
}

.block--popup-active {
    display: block;
}

.block--popup.-loading {
    .map-popup__content {
        background-image: url('../../images/poi-text-loading.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        min-height: 120px;
    }
}

.block__close {
    display: none;

    position: absolute;
    top: 2px;
    right: 20px;
    z-index: -1;

    color: get-color("black");
    font-weight: 700;
    margin-top: -28px;
    display: inline-block;

    background-color: #fff;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 5px 5px 0 0;
}

.block__bg {
    box-sizing: border-box;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    background: rgba(get-color("gray-7"), 0.7);
}

.block--clean {
    box-shadow: none;
    background: none;
}

.block__header {
    display: flex;
}

.block__title {
    display: block;
    flex: 1;
}

.block__tags {
    display: block;
    float: right;
    text-align: right;
    max-width: 50%;
    margin-left: 7px;
}

.block__content {
    padding-top: 10px;

    &-warning {
        border: 1px solid get-color("black");
        padding: 10px;
        margin-bottom: 1em;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}
