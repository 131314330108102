/* BREADCRUMBS */
.breadcrumbs {
    box-sizing: border-box;

    width: auto;
    margin: 0;
    margin-top: -2rem;

    padding: 10px 0 20px 0;

    @include screen-size(medium, large) {
        // padding: 10px 0 50px 0;
    }
}

.breadcrumb-container {
    box-sizing: border-box;
    position: relative;
    z-index: 33;
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    @include screen-size(large) {
        padding-left: 35px;
        padding-right: 35px;
        margin-bottom: -82px;
    }
}

.breadcrumb-container--inverted {
    margin-bottom: -82px;
}

.breadcrumbs__content {
    font-size: 13px;
    line-height: 1.25;
    font-weight: 400;

    display: block;
    margin: 0;
    padding: 0;

    position: relative;
    list-style: none;


    @include screen-size(medium, large) {
        font-size: 16px;
    }
}


.breadcrumb__item {
    &:before {
        display: none;
    }

    font-size: 18px;
    // line-height: 1.25;
    display: inline-block;
    font-family: $font-primary;
    color: get-color("primary");
    font-weight: 400;

    & > a {
        font-size: 18px;
        // line-height: 1.25;
        font-weight: 700;
        text-decoration: none;
        font-weight: 700;
        color: get-color("primary");

        &:hover, &:focus {
            text-decoration: underline;
        }

        @include screen-size(mini, small) {
            font-size: 13px;
            vertical-align: bottom;
            display: inline-block;
        }
    }

    @include screen-size(mini, small) {
        font-size: 13px;
        &:not(.breadcrumb__item--filler) {
            display: none;
        }

        &:first-child,
        &:last-child,
        &:nth-last-child(2) {
            display: inline-block;
            & > .breadcrumbs__icon {
                display: inline-block;
            }
        }
    }
}

.breadcrumb__current {
    display: inline-block;
}

.breadcrumb__item--filler {
    @include screen-size(medium, large) {
        display: none;
    }
}

.breadcrumbs__icon {
    position: relative;
    // font-size: 15px;
    font-style: normal;
    font-weight: 400;

    color: get-color("primary");
    top: 0;

    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;

    @include screen-size(medium, large) {
        // font-size: 18px;
        top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

.breadcrumbs--inverted {
    .breadcrumb__item,
    .breadcrumb__item  > a,
    .breadcrumbs__icon {
        color: get-color("white");
    }
}
