/* MAP-CONTAINER */
.map-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: #D5E6CB;
    overflow-x: hidden;

    @include screen-size(mini, small, medium) {
        left: 100vw;
        height: calc(100% - 58px);
        top: 0;
        bottom: auto;
    }
}
