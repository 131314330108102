/* NAV-SEARCH */
.nav-search {
    display: inline-block;
    & > a {
        padding: 0 0 0 (25px/2);
        font-size: 17px;
        font-weight: 700;
        color: get-color("white");
        line-height: 1;

        .svg-icon {
            position: relative;
            top: 3px;
            color: inherit;
        }
    }
}