/* MASTHEAD */
/* MASTHEAD VARIABLES */
$masthead-background-color:                 get-color(white);
$masthead-border-width:                     1px;
$masthead-border-style:                     solid;
$masthead-border-color:                     #e9e9e9;

$masthead-margin-bottom-large:              10px;
$masthead-margin-bottom-medium:             10px;
$masthead-margin-bottom-small:              10px;

$masthead-padding-bottom-large:             $default-block-padding;
$masthead-padding-bottom-medium:            $default-block-padding;
$masthead-padding-bottom-small:             $default-block-padding;

.masthead {
    @include clearfix;
    background-color: $masthead-background-color;
    border-bottom: $masthead-border-width $masthead-border-style $masthead-border-color;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: hidden;

    @media print {
        border-bottom: 0;
    }

    .theme-region &{
        background: get-color("primary");
        padding: 10px;
    }
}

.masthead__top {
    @include clearfix;
    display: block;

    @include screen-size(mini, small, medium) {
        background: get-color("primary");
        position: relative;
        z-index: 2;
    }
    @include screen-size(large) {
        padding-left: 130px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media print {
        display: none;
    }
}

.masthead__slogan {
    box-sizing: border-box;

    background: get-color("white");

    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;

    @include screen-size(mini, small, medium) {
        display: none;
    }

    @include screen-size(large) {
        float: left;
    }
}
