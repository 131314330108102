/* FILTER-BG */
.filter-bg {
    display: block;
    z-index: 1;

    @include screen-size(mini, small) {
        transition: opacity .3s linear, transform .3s .3s ease;
        opacity: 0;
        background: rgba(get-color("black"), .5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        outline: 0!important;
        -webkit-backface-visibility: hidden;
        transform: translate3d(-100%, 0, 0);
    }
    @include screen-size(medium, large) {
        display: none;
    }
}

.filter-bg--active {
    @include screen-size(mini, small) {
        transition: opacity .3s;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
