/* FILTER__FIELD */

// Defaults & resets
.filter__field {
    font-size: 16px;
    line-height: normal;
    margin: 0 0 .5em 0;
    width: 100%;
    padding: 10px;

    &[disabled] + label {
        opacity: .4;
        cursor: default;
    }
}


.filter__field--date,
.filter__field--email,
.filter__field--number,
.filter__field--password,
.filter__field--search,
.filter__field--tel,
.filter__field--text,
.filter__field--time {
    @extend .filter__field;
    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS
    border-radius: 0;

    box-sizing: border-box;
    display: inline-block;
    padding: 10px;
    word-break: normal;
    border: 1px solid get-color("gray");

    background-color: get-color("white");
    color: get-color("black");


    @include input-placeholder {
        color: get-color("gray-2");
    }

    &:focus {
        background-color: get-color("white");
        border-color: get-color("gray-2");
        outline: none;
    }
}
