/* FILTER__LABEL */
.filter__label {
    display: inline-block;
    cursor: pointer;

    color: get-color("black");

    font-size: 16px;
    line-height: 1.25;
    margin: 0 0 .5em 0;

    @include screen-size(mini, small) {
        display: block;
    }
}

.filter__label--checkbox,
.filter__label--radio {
    @extend .filter__label;
    position: relative;

    vertical-align: bottom;

    line-height: 1;

    margin-bottom: 0.5em;
    padding-right: 1.5em;

    @include screen-size(mini, small, medium) {
        margin-bottom: 1em;
    }
}
