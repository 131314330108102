/* Form Message */
.form__message {
    display: block;
    background: get-color("red");
    color: get-color("white");

    border-radius: 0 0 2px 2px;

    padding: 1px 5px 1px 5px;
    font-size: 13px;
}

.form__message--icon:before {
    padding-right: 8px;
}