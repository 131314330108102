/* CART */
.cart {

}

.cart__continue-shopping {
    display: block;
    @include screen-size(mini, small) {
        width: 100%;
        text-align: center;
    }
    @include screen-size(medium, large) {
        float: left;
        width: 48%;
        line-height: 45px;
    }
}

.cart__next-step {
    display: block;
    @include screen-size(mini, small) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    @include screen-size(medium, large) {
        float: right;
        width: 48%;
        text-align: right;
    }
}