/* NAV-META */
.nav-meta {
    box-sizing: border-box;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;

    float: right;
    margin-right: 20px;

    @include screen-size(mini, small, medium) {
        display: none;
    }

    & > a {
        color: get-color("blue-4");
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}
