@include screen-size(large) {
    .filter__wrapper {
        &.filter__wrapper--is-list {
            position: static;

            .block-container {
                display: block;
            }

            .map-container {
                display: none;
            }
        }

        &.filter__wrapper--is-list {
            .button--filter {
                display: none;
            }
            .filter-close-cross {
                display: none;
            }
        }

        &.filter__wrapper--is-map {
            .list-container {
                display: none;
            }

            .map-container {
                display: block;
            }
        }

        &.filter__wrapper--is-map {
            .filter-close-cross {
                display: block;
            }
        }
    }

    .filter__wrapper.-fullscreen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// With mobile menu
@include screen-size(mini, small, medium) {
    .filter__wrapper {
        position: relative;
        width: 100vw;
        height: 100%;
        transition: transform .5s ease-in-out;

        &.filter__wrapper--is-map {
            transform: translateX(-100vw);
        }

        &.filter__wrapper--is-filter {
            transform: translateX(-200vw);
        }
    }
}
