/* LOGO */
.logo {
    position: absolute;
    top: 50px;
    left: 10px;
    background: get-color("white");
    padding: 10px;
    border-radius: 100%;
    box-shadow: -1.378px 4.806px 3px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;

    @include screen-size(mini, small, medium) {
        width: 54px;
        height: 54px;
        padding: 6px;
        top: 4px;
        z-index: 15;
    }

    @include screen-size(large) {
        top: 0;
    }

    @media print {
        position: static;
    }
}

.logo-haspengouw {

}
