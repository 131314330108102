/* WALKER-ICON */
.walker-icon {
    transition: all .3s ease;

    position: relative;
    border-radius: 100%;
    display: inline-block;

    $padding-top: 8px;

    width: 45px;
    height: 45px - $padding-top;
    padding-top: $padding-top;

    text-align: center;
    font-size: 28px;
    color: get-color("white");

    box-shadow: 0 3px 5px rgba(#000, .2);
    background-color: rgb(4,47,61);
    background-image: linear-gradient(
        0deg,
        rgb(4,47,61),
        rgb(52,79,92)
    );


    .svg-icon {
        stroke: black;
        stroke-width: 1px;
        stroke-linecap: round;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: -5px;
        margin-left: -10px;
        z-index: 0;

        border: {
            top:    16px solid rgb(4,47,61);
            right:  10px solid transparent;
            bottom: 16px solid transparent;
            left:   10px solid transparent;
        }
    }


}

.walker-icon--clickable {
    cursor: pointer;

    &:hover, &:focus {
        transform: translate3d(0,2px,0) scale(0.95);
        background-color: darken(rgb(4,47,61), 10%);
        background-image: linear-gradient(
            0deg,
            darken(rgb(4,47,61), 10%),
            darken(rgb(52,79,92), 10%)
        );
        &:after {
            content: '';
            border-top: 16px solid darken(rgb(4,47,61), 10%);
        }
    }
}