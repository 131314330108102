.info-container {
    @include screen-size(mini, small, medium) {
        position: absolute;
        top: 0;
        width: 100vw;
        left: 0;
        height: calc(100% - 58px);
        overflow: scroll;
    }
}
