/* form__LABEL */
.form__label {
    display: inline-block;
    cursor: pointer;

    color: get-color("black-2");

    font-size: 18px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0 0 .5em 0;

    @include screen-size(mini, small) {
        display: block;
    }
}

.form__label--inline {
    line-height: 39px;
}

.form__label--checkbox,
.form__label--radio {
    position: relative;

    display: inline-block;
    vertical-align: bottom;

    cursor: pointer;
    line-height: 1;

    margin-bottom: 3px;
    padding-right: 1.5em;

    &.text--small {
        margin-bottom: .5em;
    }
}
