/* BLOCK-CONTAINER */
.list-container {
    @include default-typography((font-size, line-height));
    box-sizing: border-box;
    // width: 100%;
    padding-right: 10px;
    padding-top: 1rem;
    flex: 1;
    display: block;

    @include screen-size(large) {
        float: left;
    }

    @include screen-size(large) {
        .filter__wrapper--is-list & {
            padding-left: 272px;
        }
    }

    @include screen-size(mini, small, medium) {
        left: 0;
        height: calc(100% - 58px);
        overflow: scroll;
        padding-right: 0;
    }
}

.list-container--active {
    @include screen-size(medium) {
        padding-left: 20px;
        padding-top: 60px;
    }
}

.list-container--alt {
    @include screen-size(mini, small, medium) {
        float: none;
    }
    @include screen-size(medium) {
        padding-top: 60px;
    }
}
