/* TABLINKS */
.tablinks {
    @include clearfix;
    display: block;
    margin-bottom: 1rem;

    &.filterable {
        @include screen-size(mini, small, medium) {
            display: none;
        }
    }
}

.tablinks__item {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: get-color("blue");
    &:hover, &:focus {
        text-decoration: none;
    }

    input[type=radio]:checked + &{
        color: get-color("gray-4");
        text-decoration: none;
        font-style: italic;
    }
}

.tablinks__separator {
    position: relative;
    top: 5px;

    display: inline-block;
    width: 1px;
    height: 18px;
    margin: 0 5px;
    padding: 0;
    background: get-color("gray-5");
}
