$tweakpoint-large: 1120px;
$tweakpoint-medium: 850px;
$tweakpoint-small: 780px;

.promotion {
    @extend .wrapper;
    padding: 166px 4em;
    background-image: url('../../images/promotion-background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    @media (max-width: $tweakpoint-small) {
        display: block;
        padding: 100px 4vw;
    }

    &__box {
        background-color: white;
        padding: 30px 30px 48px 215px;
        max-width: 720px;
        position: relative;
        box-sizing: border-box;
        border-radius: 4px;

        @media (max-width: $tweakpoint-medium) {
            padding-left: 20vw;
        }

        @media (max-width: $tweakpoint-small) {
            max-width: none;
            padding-left: 17vw;
        }

        > img {
            display: inline-block;
            left: -205px;
            max-width: 340px;
            width: 30vw;
            position: absolute;
            top: -123px;

            @media (max-width: $tweakpoint-large) {
                left: -14vw;
                top: -14vw;
            }

            @media (max-width: $tweakpoint-small) {
                width: 30vw;
            }
        }
    }
}
