/* FOOTER */
.footer {
    box-shadow: 0 -2px 2px 0 rgba(get-color("gray"), 46%);
    background: get-color("white");
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;

    padding-top: 18px;
    padding-bottom: 4px;

    a {
        color: get-color("primary");
        margin: 0 1em;
        vertical-align: middle;
    }

    .copy__copy {
        margin-right: 1em;
    }

    @media (max-width: 990px) {
        text-align: center;

        a {
            margin: 0 .6em;
        }

        .copy__copy {
            display: block;
            margin: 0 0 .5em 0;
        }
    }
}
