svg *:not([fill]) {
    fill: currentColor;
}

.svg-icon {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: inherit;
    fill: currentColor;
}

.svg-icon--print {
    position: relative;
    top: 2px;
    margin-right: 8px;
}
