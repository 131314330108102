details:not([class]), .details {
    border: 1px solid #DEDEDE;
    border-left: 0;
    border-right: 0;

    > summary {
        padding: .8em 40px .8em 0;
        cursor: pointer;
        list-style: none;
        position: relative;

        &::-webkit-details-marker {
            display: none;
        }

        &::-moz-list-bullet {
            list-style-type: none;
            display: block;
        }

        &::after {
            content: '';
            display: block;
            mask-image: url('../../images/arrow.svg');
            width: 14px;
            height: 8px;
            color: transparent;
            background-color: get-color('black-2');
            position: absolute;
            right: 0;
            top: 1.25em;

            @include target-ie() {
                display: none;
            }
        }

        &:focus {
            outline: none;
        }
    }

    + details {
        border-top: 0;
    }

    &[open] {
        summary {
            &::after {
                background-color: get-color('primary');
            }

            > h2 {
                color: get-color('primary');
            }
        }
    }
}

.details--filter {
    summary {
        &:focus {
            outline: none;
        }
    }
}
