.hexagon-icon {
    width: 27px;
    height: 14px;
    background: get-color("white");
    position: relative;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    text-align: center;

    &:before {
        content: "";
        position: absolute;
        top: -8px;
        left: 0;
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 8px solid get-color("white");
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 8px solid get-color("white");
    }
}


.hexagon-icon__inner--triangle {
    display: inline-block;

    width: 0;
    height: 0;

    position: relative;
    top: -8px;

    border: {
        top:    6px solid transparent;
        right:  6px solid transparent;
        bottom: 12px solid get-color("blue-3");
        left:   6px solid transparent;
    }
}