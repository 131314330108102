.overview {
    .meta {
        position: relative;
        top: -1em;
    }

    p {
        margin-bottom: 1em;
    }

    @include screen-size(mini) {
        article {
            img {
                margin-bottom: 1rem;
            }
        }
    }
}