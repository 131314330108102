/* Img */
.img-link {
    box-sizing: border-box;
    display: inline-block;
    position: relative;

    // @extend .img-hover;

    &:hover, &:focus {
        background-color: $img-hover-background-color;
        text-decoration: none;
    }

    img {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
    }
}

.img-link--no-margin {
    margin: 0;
}

.img-link--alt {
    box-sizing: border-box;
    display: inline-block;
    position: relative;

    // @extend .img-hover--alt;

    &:hover, &:focus {
        background-color: $img-hover-background-color;
        text-decoration: none;
    }

    img {
        box-sizing: border-box;
        margin: 0;
    }
}

.img-link--left {
    float: left;
}

.img-link--right {
    @include screen-size(mini) {
        width: 100%;
    }
    @include screen-size(mini, small) {
        margin-bottom: 10px;
    }
    @include screen-size(small) {
        float: right;
        margin-left: 10px;
    }
    @include screen-size(medium, large) {
        float: right;
    }
}