/* SEPARATOR */
.separator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: get-color("gray");
    border: 0;

    margin: 1rem 0;

    float: none;
    clear: both;
    overflow: hidden;
}