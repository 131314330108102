/* MAIN */
.main {
    position: relative;

    &.-fullscreen {
        @include screen-size(mini, small, medium) {
            height: calc(100% - 48px); // minus header height
            overflow: hidden;
        }
    }
}
