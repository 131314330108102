/* form__FIELD */

// Defaults & resets
.form__field {
    vertical-align: top;
    font-size: 16px;
    line-height: normal;
    margin: 0;
    width: 100%;
    padding: 10px;

    &--inline {
        width: auto;
    }
}


.form__field--date,
.form__field--email,
.form__field--number,
.form__field--password,
.form__field--search,
.form__field--tel,
.form__field--text,
.form__field--textarea,
.form__field--time {
    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS
    border-radius: 2px;

    box-sizing: border-box;
    display: inline-block;
    padding: 8px;
    word-break: normal;
    border: 2px solid get-color("primary");
    min-width: 40px;

    background-color: get-color("white");
    color: get-color("black");

    @include input-placeholder {
        color: get-color("gray-2");
    }

    &:focus {
        background-color: get-color("white");
        border-color: get-color("black");
        outline: none;
    }

    .form__entry--invalid &{
        border-radius: 2px 2px 0 0;
        border-color: get-color("red");
        background-color: lighten(get-color("red"), 45%);

        &:focus {
            background-color: lighten(get-color("red"), 47%);
        }
    }
}

.form__field--search {
    height: 50px;
}
