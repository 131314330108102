/* CREDIT */
.credit {
    float: right;
    margin-bottom: 1em;

    @media (max-width: 990px) {
        display: block;
        float: none;
        text-align: center;
        margin-top: .5em;
    }
}
