// Helper functions & mixins
@import 'base/functions/*';
@import 'base/mixins/*';

// Variables
@import 'base/config/variables.scss';

// Crossbrowser normalisations
@import 'base/normalize.scss';

// Helper classes
@import 'base/helpers/helpers.scss';

// Icons
@import 'base/icon.scss';
@import 'base/svg-icon.scss';

// Typography: Markup styles
@import 'base/elements/*';

// Global layout (header & footer)
@import 'base/layout.scss';

// All website components
@import './components/**/*.scss';

// All website helpers
@import 'base/helpers/*';

// Print
@import 'base/print.scss';
