/* OVERVIEW-ITEM */
.overview-item {
    margin-bottom: 2em;
}

.overview-item__title {
    @extend .title-2;
    font-size: 23px;
}

.overview-item__title--alt {
    font-weight: normal;
    font-size: 18px;
}

.overview-item__title--extra {
    margin-bottom: 0;
}

.overview-item__extra {
    color: get-color("secondary");
}

.overview-item__img-link {
    display: block;
    margin-bottom: .5em;
    @include screen-size(mini, small) {
        text-align: center;
    }
}
