/* Filter-Result */
.filter-results {
    position: relative;
    left: 0;

    &.-is-active {
        @include screen-size(mini, small, medium) {
            left: 270px;
        }
    }
}
