/* MASTHEAD SEARCH */
.masthead__search {
    display: inline-block;
    max-width: 263px;
    vertical-align: middle;

    .form__fieldset {
        position: relative;
        overflow: visible;
        input[type=search] {
            border-radius: 0px;
            background-color: get-color(white);
            padding-right: 30px;
            border: 1px solid #999999;
            &:focus {
                background-color: get-color(white);
            }
            @include input-placeholder {
                font-style: italic;
                text-decoration: underline;
                color: get-color(black);
                font-size: 16px;
                position: relative;
            }
        }

        [type=submit] {
            position: absolute;
            right: 10px;
            color: #000;

            .icon {
                vertical-align: bottom;
            }
        }
    }

    @media screen and (max-width:869px) {
        display: none;
        margin: 0 auto;
        margin-bottom: 20px;
    }

}