.map-menu {
    @include screen-size(large) {
        display: none;
    }

    @media print {
        display: none;
    }

    @include screen-size(mini, small, medium) {
        bottom: 0;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, .1);
        display: flex;
        height: 58px;
        justify-content: center;
        left: 0;
        position: fixed;
        width: 100%;

        > a {
            align-items: center;
            background-color: get-color('primary');
            color: white;
            display: flex;
            flex: 1 1 100%;
            font-size: 11px;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;

            &::before {
                content: '';
                display: block;
                background-color: currentColor;
                mask-size: contain;
                display: inline-block;
                margin-right: 10px;
            }

            &.-icon-list::before {
                width: 21px;
                height: 15px;
                mask-image: url('../../images/list-icon.svg');
            }

            &.-icon-map::before {
                width: 26px;
                height: 22px;
                mask-image: url('../../images/map-icon.svg');
            }

            &.-icon-filter::before {
                width: 22px;
                height: 14px;
                mask-image: url('../../images/filter-icon.svg');
            }

            &.-icon-info::before {
                width: 20px;
                height: 20px;
                mask-image: url('../../images/info-icon.svg');
            }

            &.-back-link {
                flex-basis: 250px;

                svg {
                    max-height: 30px;
                    width: 24px;
                }
            }

            &:not(:last-child) {
                border-right: 1px solid get-color('green-2');
            }

            &:hover, &:active, &:focus {
                color: black;
                text-decoration: none;
            }

            &.-is-active {
                color: black;
                background-color: white;
            }
        }
    }
}



