%time {
    font-size: 90%;
    color: get-color('gray-4');
}

time:not([class]), .time {
    @extend %time;
}

.time--detail {
    @extend %time;
    margin-bottom: 3em;
    display: block;

    @include screen-size(mini, small, medium) {
        margin-bottom: 2em;
        margin-top: 1em;
    }
}


