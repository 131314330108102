@import '~glightbox/dist/css/glightbox.css';

.glightbox-clean .gslide-title {
    color: white;
    margin: 0;
    max-width: none;
    text-align: center;
}

.glightbox-clean .gslide-description {
    background-color: transparent;
    color: white;
    text-align: center;
}

.desc-top .gslide-image img, .desc-bottom .gslide-image img {
    border: 10px solid white;
    max-height: 90vh;
    width: auto;
}

.gslide-image img {
    max-width: calc(100% - 30px);
}
