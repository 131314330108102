/* ==========================================================================
   Global layout: header & footer
   ========================================================================== */

html, body {
    min-width: 320px;
    width: 100%;
    font-family: $default-font-family;
}

html {
    @include default-typography((font-size, line-height));
    color: $default-font-color;
}

body {
    position: relative;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;

    @if map-has-key($screen-sizes, medium) {
        min-width: 320px;
    } @else {
        min-width: map-get(map-get(map-get($screen-sizes, large), grid), screen-min-width);
    }

    // Don't let long words break the layout on small area's
    word-wrap: break-word;
    overflow-x: hidden;

    @include screen-size(mini, small) {
        overflow-x: auto;
    }
}

[data-component-config] {
    display: none;
}

.layout-grid {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 34px;
    grid-row-gap: 54px;
    margin-bottom: 2em;

    @include target-ie() {
        display: block;
        overflow: hidden;
        margin-right: -34px;

        > * {
            float: left;
            margin-right: 34px;
            margin-bottom: 54px;
        }
    }

    &.-columns-2 {
        @include screen-size(medium, large) {
            grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
        }

        @include target-ie() {
            > * {
                width: 450px;
            }
        }
    }

    &.-columns-3 {
        @include screen-size(medium, large) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        @include target-ie() {
            > * {
                width: 300px;
            }
        }
    }
}

.layout-gallery {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    margin-bottom: 2em;
    grid-template-columns: repeat(2, 1fr);
}

.no-overflow {
    @include screen-size(mini, small) {
        overflow: hidden;
    }
}
