/* VARIABLES */
$call-to-action-width-large:                100%;
$call-to-action-width-medium:               100%;
$call-to-action-width-small:                100%;

$call-to-action-padding-large:              40px 40px 40px 40px;
$call-to-action-padding-medium:             40px 40px 30px 40px;
$call-to-action-padding-small:              20px 20px 10px 20px;

$call-to-action-font-size-large:            30px;
$call-to-action-font-size-medium:           26px;
$call-to-action-font-size-small:            24px;

$call-to-action-font-color:                 get-color(white);

$call-to-action-background-color:           transparent;
$call-to-action-border-color:               get-color(gray);
$call-to-action-border-width:               1px;

$call-to-action-margin-bottom:              $default-block-margin;


/* CALL TO ACTION */
.call-to-action {
    box-sizing: border-box;

    @include screen-size( mini, small ) {
        width: $call-to-action-width-small;
        padding: $call-to-action-padding-small;
    }
    @include screen-size( medium ) {
        width: $call-to-action-width-medium;
        padding: $call-to-action-padding-medium;
    }
    @include screen-size( large ) {
        width: $call-to-action-width-large;
        padding: $call-to-action-padding-large;
    }

    text-align: center;

    margin: 0 auto;
    margin-bottom: $call-to-action-margin-bottom;

    border: $call-to-action-border-width solid $call-to-action-border-color;
    background-color: $call-to-action-background-color;
}

.call-to-action__title {
    color: get-color(primary);
    font-size: 28px;
}

.call-to-action__content {
    margin: 0 auto;

    p {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.call-to-action__spacer {
    display: inline-block;

    @include screen-size(medium, large) {
        width: 40px;
    }

    @include screen-size(mini, small, medium) {
        display: none;
    }
}

.call-to-action__button {
    margin-bottom: 0;

    @include screen-size(mini, small, medium) {
        margin-bottom: 10px;
    }

    @include screen-size(mini, small) {
        width: 100%;
    }
}

.call-to-action__content {
    box-sizing: border-box;

    @include screen-size(mini, small) {
        font-size: $call-to-action-font-size-small;
    }

    @include screen-size(medium) {
        font-size: $call-to-action-font-size-medium;
    }

    @include screen-size(large) {
        font-size: $call-to-action-font-size-large;
    }

    position: relative;
    color: $call-to-action-font-color;
    background-color: $call-to-action-background-color;
}