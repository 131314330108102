/* Variables */
$table-border-color:                        transparent;

$table-head-color:                          $default-font-color;
$table-head-background-color:               #e9e9e9;

$table-row-color:                           $default-font-color;
$table-row-background-color-even:           #f9f9f9;
$table-row-background-color-odd:            get-color(white);


/* Tables */
.table {
    @include default-typography(margin);

    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    // font-size: 0;
    // line-height: 0;

    tr {
        &:nth-child(even) {
            background-color: $table-row-background-color-even;
        }
        &:nth-child(odd) {
            background-color: $table-row-background-color-odd;
        }
    }

    th, td {
        color: $table-row-color;
        padding: 0.5rem;
        border: 1px solid $table-border-color;
    }

    th {
        color: $table-head-color;
        background: $table-head-background-color;
        vertical-align: middle;
        text-align: center;
    }

    tbody {
        th, td {
            vertical-align: top;
            text-align: left;
        }
    }

    ul, ol {
        padding-left: 1rem;
    }

    @include screen-size(mini, small, medium) {
        display: block;
        position: relative;
        border-collapse: collapse;
        border-spacing: 0;

        th, td {
            margin: 0;
            vertical-align: top;

            &:last-child {
                border-bottom: 1px solid $table-border-color;
            }
        }

        th {
            text-align: left;
            display: block;
            border-right: 0;
        }

        thead {
            display: block;
            float: left;

            tr {
                display: block;
                margin: 0;
                padding: 0;
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            tr {
                display: inline-block;
                vertical-align: top;
                border-left: 1px solid $table-border-color;
            }
        }

        td {
            display: block;
            min-height: 1.25em;
            text-align: left;
        }

        th {
            border-bottom: 0;
            border-left: 0;
        }

        td {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
        }
    }

}

.table__td--padded-left {
    padding-left: 20px;
}