/* Variables */
$img-margin-top:            0rem; // Align with possible surrounding text


/* Style */
img {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: $img-margin-top;
    max-width: 100%; // Never stretch images
    height: auto; // Always keep original image ratio
}