.map-box {
    @extend .box2;
    background-color: get-color('white-2');
    margin-bottom: 3em;
    position: relative;

    @include screen-size(large) {
        margin-top: -650px;
        max-width: 540px;
        min-height: 650px;
    }

    &__meta {
        line-height: 2;
    }

    &__image {
        position: relative;
        margin-bottom: 1em;
    }

    &__fav {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: inline-block;
        color: white;
        height: 22px;
        width: 25px;

        @media (min-width: 612px) and (max-width: $breakpoint-medium) {
            left: 490px;
            right: auto;
        }

        &[data-is-favorite='1'] {
            > svg path.-fill {
                opacity: 1;
            }
        }
    }

    &__route-warning {
        background-image: url('../../images/icon-warning.svg');
        background-position: 20px 20px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-color: white;
        border: 1px solid get-color('black');
        border-radius: 4px;
        padding: 20px 20px 20px 50px;
        margin-top: 1em;
        margin-bottom: 1em;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &__poi-warning {
        background-color: white;
        border: 1px solid get-color('black');
        border-radius: 4px;
        padding: 20px 20px 30px 20px;
        margin-top: 1em;
        margin-bottom: 1em;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}
