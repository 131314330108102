/* DROPDOWN */
.dropdown__trigger {
    cursor: pointer;

    .-is-active {
        .dropdown__toggle-open{
            display: none;
        }

        .dropdown__toggle-closed {
            display: inline-block;
        }

        .dropdown__icon {
            &:before {
                top: -3px;
                border: {
                    top:    3px solid transparent;
                    right:  3px solid transparent;
                    bottom: 3px solid get-color("primary");
                    left:   3px solid transparent;
                };
            }
        }
    }
}

.dropdown__title {
    display: block;
}

a.dropdown__trigger,
.dropdown__trigger-style
/* .dropdown__trigger*/ {
    color: get-color("primary");
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;

    position: relative;
    white-space: nowrap;

    &:hover, &:focus {
        color: get-color("black");
        text-decoration: none;
    }

    @media print {
        display: none;
    }
}

.dropdown__toggle-open {
    display: inline-block;
}

.dropdown__toggle-closed {
    display: none;
}

.dropdown__icon {
    &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 0 0 2px;

        border: {
            top:    3px solid get-color("primary");
            right:  3px solid transparent;
            bottom: 3px solid transparent;
            left:   3px solid transparent;
        };

        position: relative;
        top: 0px;
   }
}

.dropdown__content {
    display: none;

    &.-is-open {
        @media screen {
            display: block;
        }
    }
}

