/* ITEM */
.item {
    overflow: hidden;
    clear: left;
    display: block;
    &:not(:last-child) {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid get-color("gray");
    }
    &:last-child {
        margin-bottom: 60px;
    }
}

.item__image {
    float: left;
    width: 125px;
    margin-right: 20px;
}

.item__image--right {
    float: right;
    width: 160px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: .5em;
}

.item__content {
    strong {
        font-size: 115%;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

}
