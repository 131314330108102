/* Titles */
.title-1,
.title-2,
.title-3,
.title-4,
.title-5,
.title-6 {
    color: get-color("black");
    margin: 0 0 .5em 0;

    line-height: 1.25;

    display: block;
    font-family: $font-secondary;
    text-transform: none;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.title--home {
    font-family: $font-secondary;
    font-size: px-2-rem(40px);
    font-weight: 700;
    margin-bottom: 1em;
}

.title-1 {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: px-2-rem(33px);
    margin-bottom: 1em;
}

.title-2 {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: px-2-rem(26px);
}

.title-2--alt {
    font-size: px-2-rem(23px);
}

.title-3 {
    color: get-color("primary");

    font-family: $font-secondary;
    font-weight: 700;
    font-size: px-2-rem(20px);
}

.title-4 {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: px-2-rem(17px);
}

.title-5 {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: px-2-rem(15px);
}

.title-6 {
    font-family: $font-primary;
    font-weight: 400;
    font-size: px-2-rem(13px);
    text-transform: uppercase;
}

h1 { @extend .title-1; }
h2 { @extend .title-2; }
h3 { @extend .title-3; }
h4 { @extend .title-4; }
h5 { @extend .title-5; }
h6 { @extend .title-6; }
