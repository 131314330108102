/* Wrapper */
.wrapper {
    position: relative;
    width: 100%;

    &.-graylight {
        background-color: get-color('white-2');
    }

    &.-padded-3 {
        padding: 3em 0;
    }

    &.-border-top {
        border-top: 1px solid get-color('gray');
    }
}

.wrapper--default {
    box-sizing: border-box;

    border: {
        top:    5px solid get-color("white");
        bottom: 5px solid get-color("white");
    }

    @include screen-size(large) {
       min-height: 179px;
        background: transparent url('../../images/header-bg-large.jpg') center center no-repeat;
        background-size: cover;
    }
}

.wrapper--bg {
    box-sizing: border-box;

    background-position: center center;
    background-size: cover;

    overflow-x: hidden;

    @include screen-size(large) {
        height: 40vh;
        min-height: 649px;
        max-height: 700px;
        overflow: hidden;
    }

    @media print {
        overflow: visible;
    }
}

.wrapper--bg-mapped {
    @include screen-size(large) {
        background-image: none;
    }

    @media print {
        height: 20cm;
        max-height: none;
    }
}

.wrapper--gradient {
    background-image: linear-gradient(
        to top,
        #ececec,
        #fefefe
    );
}

@each $color, $hex in $colors {
    .wrapper--#{$color} {
        background: $hex;
    }
}
