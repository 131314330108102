/* FILTER-CONTAINER */
.filter-container {
    transition: all .3s ease;

    margin-right: 7px;
    display: block;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    background: get-color("white");
    box-sizing: border-box;

    @include screen-size(large) {
        width: 272px;
    }

    .filter__wrapper--is-list &{
        padding-bottom: 0;
    }

    .-is-offline &{
        display: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @include screen-size(mini, small, medium) {
        flex: none;
    }

    @include screen-size(mini, small, medium) {
        display: block;
        top: 0;
        left: 200vw;
        height: calc(100% - 58px);
        overflow: scroll;
        position: absolute;
        max-width: none;
        transform: none;
        width: 100vw;
    }

    @include screen-size(large) {
        float: left;
        flex: 1;
        outline: 0!important;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
    }

    .filter-container__scroll {
        overflow-y: scroll;
        max-height: 100%;

        .filter__wrapper--is-list &{
            height: 100%;
        }
    }
}

.filter-container--active {
    .filter__wrapper--is-map &{
        @include screen-size(large) {
            transform: translate3d(-100%, 0, 0);
        }
    }

    + .filter-overlay {
        display: block;
    }
}

.filter-container--absolute {
    @include screen-size(large) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        z-index: 10;

        overflow: hidden;
        margin-bottom: 0;
    }
}
