table.route-properties {
    th {
        text-align: left;
        font-weight: normal;
        padding-right: 1em;
        color: get-color('gray-8');
    }

    td {
        font-weight: bold;
    }
}
