/* Image hovers */
$img-hover-background-color:                 #2f3963; // The overlay color
$img-hover-opacity:                          0.2; // The opacity on the overlay color

$img-hover-content-padding:                  px-2-rem(15px) px-2-rem(20px) px-2-rem(15px) px-2-rem(20px); // The padding around the content
$img-hover-border-radius:                    0;
$img-hover-content-border-radius:            px-2-rem(3px);

$img-hover-content-color:                    get-color(white);
$img-hover-content-background-color:         transparent; // the color around the content
$img-hover-content-border:                   0; // the color around the content

$img-hover-content-font-size--large:         px-2-rem(16px);
$img-hover-content-font-size--medium:        px-2-rem(16px);
$img-hover-content-font-size--small:         px-2-rem(16px);

$img-hover-content-font-weight:              bold;
$img-hover-content-text-transform:           uppercase;


/* Img-hover */
.img-hover {
    border-radius: $img-hover-border-radius;
    &:after, .after {
        box-sizing: border-box;
        border-radius: $img-hover-content-border-radius;
        transform: translate( -50%, -50% );
        @extend .icon !optional;

        @include default-typography(font-size);
        line-height: 1;

        content: attr(data-content);
        display: none;

        white-space: nowrap; // don't break into multiple lines

        width: auto;
        padding: $img-hover-content-padding;

        color: $img-hover-content-color;
        text-align: center;
        font-weight: $img-hover-content-font-weight;
        text-transform: $img-hover-content-text-transform;

        background-color: $img-hover-content-background-color;
        border: $img-hover-content-border;

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }

    &:hover, &:focus {
        &:before, .before,
        &:after, .after {
            display: block;
        }

        img {
            opacity: $img-hover-opacity;
        }
    }
}

.img-hover--alt {
    @extend .img-hover;
    &:after, .after {
        border: 0;
        padding: 0;
        font-size: 41px;
    }
}