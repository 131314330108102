@mixin screen-size($sizes...) {
    @each $size in $sizes {
        @each $screen-size, $screen-size-config in $screen-sizes {
            $grid-config: map-get($screen-size-config, grid);
            $breakpoint: map-get($grid-config, breakpoint);

            @if $size == $screen-size {
                @if $layout-responsive and map-has-key($grid-config, screen-min-width) and map-has-key($grid-config, screen-max-width) {
                    @media only screen and (min-width: map-get($grid-config, screen-min-width)) and (max-width: map-get($grid-config, screen-max-width)) {
                        @content;
                    }
                } @elseif $layout-responsive and map-has-key($grid-config, screen-min-width) {
                    @media only screen and (min-width: map-get($grid-config, screen-min-width)) {
                        @content;
                    }
                } @elseif $layout-responsive and map-has-key($grid-config, screen-max-width) {
                    @media only screen and (max-width: map-get($grid-config, screen-max-width)) {
                        @content;
                    }
                } @else {
                    @content;
                }
            }
        }
    }
}