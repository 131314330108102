/*
 * Help us to create a better world and kill the old browsers (<= IE 7)
 */
.browse-happy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 1em;
    display: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
}
