/* NAV-METACONTAINER */
.nav-metacontainer {
    @include screen-size(mini, small, medium) {
        display: block;
        text-align: right;
        padding-right: 20px;
        padding-bottom: 10px;
    }
    @include screen-size(large) {
        display: inline-block;
        float: right;
        text-align: right;
        flex: 1 0 auto;
        padding-right: 20px;
    }
}
