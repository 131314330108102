/* NAV-CONTAINER */
.nav-container {
    @include clearfix;
    display: block;
    background: get-color("primary");

    @include screen-size(mini, small, medium) {
        transition: transform .3s ease;
        background: get-color("primary");
        position: absolute;
        width: 100%;
        transform: translateY(-100%);
        z-index: 1;
    }
    @include screen-size(large) {
        display: flex;
        align-items: baseline;
        justify-content: center;
    }

    @media print {
        display: none;
    }
}

.nav-container--active {
    @include screen-size(mini, small, medium) {
        transform: translateY(0);
    }
}
