/* ==========================================================================
   Variables
   ========================================================================== */

/* Responsive true/false */
$layout-responsive:                     true;


/* Default sizes */
$default-rem-size:                      16px; // Browser default
$default-block-margin:                  px-2-rem(30px);
$default-block-padding:                 px-2-rem(30px);

/* Colors */
$colors: (
    "purple":                             #886183, // header, title, dropdown-trigger

    "pink":                               #ff6f5f, // cart box
    "pink-2":                             #ff00b2, // tag

    "white":                              #ffffff,
    "white-2":                            #F6F6F6,

    "gray":                               #eceef1,
    "gray-2":                             #cdcdcd,
    "gray-3":                             #e6e2e2, // footer top shadow
    "gray-4":                             #787878, // filter links--active
    "gray-5":                             #d2d2d2, // filter links separator
    "gray-6":                             #cacaca, // filter close icon
    "gray-7":                             #bfbbbe, // block popup bg
    "gray-8":                             #818181, // text in blocks

    "black":                              #000000, // title
    "black-2":                            #222222, // text
    "black-3":                            #333333, // text

    "red":                                #d32c37, // tag
    "red-2":                              #5f1010, // tag

    "green"  :                            #87b83f,
    "green-2":                            #5E9F00,

    "yellow":                             #f3cf39, // tag

    "orange":                             #e88e3a, // tag

    "blue":                               #2244dd, // link
    "blue-2":                             #008298, // tag
    "blue-3":                             #3b99fc, // hexagon-icon
    "blue-4":                             #226fcb, // filter links

    "brown":                              #554241, // Haspengouw
);

$colors: map_merge($colors, (
    "primary": map_get($colors, "green"),
    "secondary": map_get($colors, "green"),
));

/* Screen adaptive config */
$breakpoint-medium: 1095px;
$breakpoint-small:  680px;
$breakpoint-mini:   379px;

$screen-sizes: (
    large: (
        typography: (
            font-size:                      18px,
            line-height:                    1.5,
            margin:                         0 0 px-2-rem(30px) 0
        ),
        grid: (
            screen-min-width:               $breakpoint-medium + 1,
            total-columns:                  12,
            column-width:                   77px, // When you set this container-max-width will be calculated for you
            gutter-width:                   34px,
            side-padding:                   30px,
            subgrids: (
                6: (3),
                7: (2,5)
            )
        )
    ),
    medium: (
        typography: (
            font-size:                      17px,
            line-height:                    1.6,
            margin:                         0 0 px-2-rem(20px) 0
        ),
        grid: (
            screen-min-width:               $breakpoint-small + 1,
            screen-max-width:               $breakpoint-medium,
            total-columns:                  12,
            gutter-width:                   20px,
            side-padding:                   20px,
            subgrids: (
                4: (1, 2, 3)
            )
        )
    ),
    small: (
        typography: (
            font-size:                      16px,
            line-height:                    1.6,
            margin:                         0 0 px-2-rem(10px) 0
        ),
        grid: (
            screen-min-width:               $breakpoint-mini + 1,
            screen-max-width:               $breakpoint-small,
            total-columns:                  3,
            gutter-width:                   10px,
            side-padding:                   15px,
            subgrids: (
                2: (1)
            )
        )
    ),
    mini: (
        typography: (
            font-size:                      14px,
            line-height:                    1.65,
            margin:                         0 0 px-2-rem(10px) 0
        ),
        grid: (
            screen-max-width:               $breakpoint-mini,
            total-columns:                  2,
            gutter-width:                   10px,
            side-padding:                   15px
        )
    )
);

/* Typography */
$default-font-family:                   Arial, sans-serif;

$font-primary:                          $default-font-family;
$font-secondary:                        $default-font-family;
$titles-font-family:                    $default-font-family;

$paragraph-max-width:                   635px;

$default-font-color:                    get-color("black-2");
$default-link-color:                    get-color("secondary");
$default-link-text-decoration:          none; // none, underline, overline, underline overline, line-through;
$default-link-text-decoration-hover:    underline; // none, underline, overline, underline overline, line-through
$default-link-color-hover:              inherit; // Hover color is commented out by default

$default-selection-color:               $default-font-color;
$default-selection-bgcolor:             #B3D4FD; // Browser default (chrome)

$img-margin-top:                        0.4rem; // Align with possible surrounding text

$hr-color:                              $default-font-color;
