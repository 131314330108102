$breakpoint-video: 850px;

.home-intro {
    background-image: url('../../images/map-background.jpg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 1px solid #DEDEDE;
    padding: 4em 0;
    margin-bottom: 4em;

    @media (max-width: $breakpoint-video) {
        padding-top: 3em;
        margin-bottom: 2em;
    }

    &__inner-container {
        @extend .grid__container;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: $breakpoint-video) {
            flex-direction: column;
        }
    }

    &__title {
        @extend .title--home;
        flex: 0 0 100%;
    }

    &__description {
        flex-basis: 40%;

        @media (max-width: $breakpoint-video) {
            margin-bottom: 2em;
        }
    }

    &__video {
        background-color: black;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, .12) 0 32px 24px -19px;
        flex-basis: 50%;
        height: 0;
        overflow: hidden;
        padding-bottom: 28.1%;
        position: relative;

        @media (max-width: $breakpoint-video) {
            padding-bottom: 56.1%;
        }

        > iframe {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }
}
